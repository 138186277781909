@if (loadingDays || (dayControls && dayControls.length === 0)) {
  <!-- TODO do we want to use this as a loading/empty state? If so check if these classes make sense -->
  <content-state
    class="-mx-3 mb-3 flex h-60 items-center justify-center border-0 border-y border-solid border-grey-200 bg-white"
    [isLoading]="loadingDays"
    icon="clipboard"
    [isEmpty]="dayControls && dayControls.length === 0"
  />
} @else {
  <div class="mb-3.5 overflow-hidden rounded-base border border-solid border-grey-200">
    <div *subscriptionPlan="PlanType.BASIC" class="flex w-full items-center justify-end bg-grey-100 p-1.5">
      <button sb-button [color]="'orange'" [size]="'sm'" [disabled]="loadingHours" (click)="setHours.emit()">
        {{ 'Recalculate' | translate }}
        @if (loadingHours) {
          <sb-spinner iconPositionEnd class="xx-small flex h-4 w-4 leading-none" />
        } @else {
          <sb-icon iconPositionEnd [name]="'history'" [size]="'sm'" />
        }
      </button>
    </div>
    <div class="max-h-60 w-full overflow-y-auto">
      <table class="w-full border-collapse text-base text-word-mark-500">
        <thead>
          <tr
            class="sticky top-0 z-10 bg-white font-semibold after:absolute after:inset-x-0 after:bottom-0 after:border-0 after:border-b after:border-solid after:border-grey-200 after:content-[''] [&>th:first-child]:pl-4 [&>th]:whitespace-nowrap [&>th]:py-1.5 [&>th]:pr-2.5"
          >
            <th [width]="isRequestInDays ? '25%' : ''">
              {{ 'Date' | translate }}
            </th>
            @if (isRequestInDays) {
              <th width="45%">{{ 'Absence' | translate }}</th>
            } @else {
              <th>{{ 'Partial day' | translate }}</th>
              <th>{{ 'From' | translate }}</th>
              <th>{{ 'Hours' | translate }}</th>
            }
            @if (canUpdateTimeOffBalances) {
              <th [width]="isRequestInDays ? '30%' : ''">
                {{ 'Balance' | translate }}
              </th>
            }
          </tr>
        </thead>
        <tbody>
          @for (control of dayControls; track trackByDateControl) {
            <tr
              class="border-0 border-b border-solid border-grey-200 [&>td:first-child]:pl-4 [&>td]:py-1 [&>td]:pr-2.5"
              [ngClass]="{ 'bg-grey-50': control.get('isWeekend').value }"
            >
              <td>
                <div class="flex">
                  <span class="whitespace-nowrap font-semibold">
                    {{ control.get('date').value | date: 'EEEEEE. dd MMM' }}
                  </span>

                  <div class="ml-1 flex items-center empty:hidden">
                    @if (absenteeDaysData[control.get('date').value]?.hasAbsenceConflict) {
                      <shift-warning-tooltip [absenceWarning]="true">
                        {{ 'Already absent' | translate }}
                      </shift-warning-tooltip>
                    }
                    @if (absenceRestrictionConflicts[control.get('date').value]) {
                      <shift-warning-tooltip [absenceWarning]="true">
                        {{ 'No absence may be requested for this day' | translate }}
                      </shift-warning-tooltip>
                    }
                    @if (absenteeDaysData[control.get('date').value]?.hasScheduleConflict) {
                      <!-- TODO why is this not part of shift-warning-tooltip? -->
                      <icon
                        class="si timesheet-warning_icon"
                        svgIcon="circle"
                        [sbTooltip]="
                          isRequestInDays
                            ? ('Absence does not match schedule.' | translate)
                            : ('Absence hours do not match scheduled hours. Expected hours are: [expectedHours].'
                              | translate
                              | translationParams
                                : { expectedHours: absenteeDaysData[control.get('date').value]?.expectedHours })
                        "
                      />
                    }
                    @if (absenteeDaysData[control.get('date').value]?.hasContractConflict) {
                      <icon
                        class="si timesheet-warning_icon"
                        svgIcon="circle"
                        [sbTooltip]="
                          isRequestInDays
                            ? ('Absence does not match contract.' | translate)
                            : ('Absence hours do not match contract hours. Expected hours are: [expectedHours].'
                              | translate
                              | translationParams
                                : { expectedHours: absenteeDaysData[control.get('date').value]?.expectedHours })
                        "
                      />
                    }
                  </div>
                </div>
              </td>
              @if (isRequestInDays) {
                <td>
                  <div class="flex space-x-2.5">
                    <select class="select min-w-auto" [formControl]="control.get('absenceDayOption')">
                      @for (absenceDayOption of absenceDayOptions; track $index) {
                        <option [value]="absenceDayOption.value">
                          {{ absenceDayOption.label | translate }}
                        </option>
                      }
                    </select>
                    @if (
                      control.get('absenceDayOption')?.value === AbsenceDayOption.HALF_FROM ||
                      control.get('absenceDayOption')?.value === AbsenceDayOption.HALF_UNTIL
                    ) {
                      <time-input [formControl]="control.get('day_time')" [hideInputAddon]="true" />
                    }
                  </div>
                </td>
              } @else {
                <td>
                  <div class="flex items-center justify-center">
                    <label class="checkbox">
                      <input [formControl]="control.get('partial_day')" type="checkbox" class="checkbox-input" />
                      <span class="checkbox-indicator"></span>
                      <span class="checkbox-description"></span>
                    </label>
                  </div>
                </td>
                <td>
                  <time-input [formControl]="control.get('start_time')" [hideInputAddon]="true" />
                </td>
                <td
                  [ngClass]="{
                    '[&_input]:border-red-500': control.get('hours').dirty && !control.get('hours').valid
                  }"
                >
                  <decimal-time-input class="block w-16" [decimals]="5" [formControl]="control.get('hours')" />
                </td>
              }
              @if (canUpdateTimeOffBalances) {
                <td>
                  @if (timeOffBalanceOptions?.length === 0) {
                    <div>
                      {{ 'None' | translate }}
                    </div>
                  } @else if (timeOffBalanceOptions?.length === 1) {
                    <div>
                      {{ timeOffBalanceOptions[0].text }}
                    </div>
                  } @else if (timeOffBalanceOptions?.length > 1) {
                    <sb-select
                      class="block w-28"
                      [items]="timeOffBalanceOptions"
                      [formControl]="control.get('time_off_balance_id')"
                      [searchable]="true"
                    />
                  }
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="w-full bg-grey-100 px-4 py-1.5 text-base">
      <span>
        <span class="font-semibold">{{ 'Total' | translate }}: </span>
        {{
          isRequestInDays
            ? (totalDays | decimalToDurationFormat: AbsenceOptionUnit.DAYS)
            : (totalHours | decimalToDurationFormat: AbsenceOptionUnit.HOURS)
        }}
      </span>
    </div>
  </div>
}

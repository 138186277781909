import { AbsenceDayOption } from './employee-absentee-days.enum';
import { AbsenceDayControl } from './employee-absentee-days.model';

export const totalHours = (dayControls: AbsenceDayControl[]): number =>
  dayControls.reduce((sum, curr) => (sum += parseFloat(curr.hours)), 0);

export const totalDays = (dayControls: AbsenceDayControl[]): number =>
  dayControls.reduce((sum, curr) => {
    if (curr.absenceDayOption === AbsenceDayOption.FULL) {
      return sum + 1;
    }
    if (curr.absenceDayOption === AbsenceDayOption.HALF_FROM || curr.absenceDayOption === AbsenceDayOption.HALF_UNTIL) {
      return sum + 0.5;
    }
    return sum;
  }, 0);

export const totalWaitHours = (amount: number, dayControls?: AbsenceDayControl[]): number => {
  if (amount === 0) {
    return amount;
  }
  return dayControls?.reduce((acc, curr) => {
    const hours = parseFloat(curr.hours);
    if (amount > 0 && hours > 0) {
      acc += hours;
      amount -= 1;
    }
    return acc;
  }, 0);
};

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AbsenceStatus, EnhancedAbsenceModel, RosterActions } from '@reducers/orm/absence/absence.model';
import { AbsenceService } from '@reducers/orm/absence/absence.service';
import { TooltipModule } from '@sb/tooltip';
import { ButtonComponent, CheckboxComponent, IconComponent, SbDialogSharedModule, StatusList } from '@sb/ui';
import { finalize, switchMap, take } from 'rxjs';

import { PlanType } from '../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { ScheduleHelperService } from '../../../+authenticated/+schedule/shared/schedule-helper.service';
import { LaddaDirective } from '../../../shared/ladda/ladda.directive';
import { SbFormFieldComponent } from '../../../shared/sb-lib/forms/sb-form-field.component';
import { SubscriptionPlanDirective } from '../../../shared/subscription-plan/subscription-plan.directive';
import { getTrackEventFn } from './absence-request.helper';

export interface AbsenceApprovalResult {
  action: 'back' | 'approved';
}

interface DialogData {
  absence: EnhancedAbsenceModel;
  isOwnAbsence: boolean;
  trackEvent: ReturnType<typeof getTrackEventFn>;
}

@Component({
  selector: 'absence-approval',
  standalone: true,
  imports: [
    SbDialogSharedModule,
    TranslateModule,
    ReactiveFormsModule,
    TooltipModule,
    SubscriptionPlanDirective,
    LaddaDirective,
    ButtonComponent,
    SbFormFieldComponent,
    CheckboxComponent,
    IconComponent,
  ],
  templateUrl: './absence-approval.component.html',
})
export class AbsenceApprovalComponent implements OnInit {
  public absence: EnhancedAbsenceModel;
  public isOwnAbsence: boolean;
  public list: StatusList;
  public saving = false;

  public AbsenceStatus = AbsenceStatus;
  public RosterActions = RosterActions;
  public PlanType = PlanType;

  public form = new FormGroup({
    roster_action: new FormControl(RosterActions.NONE, Validators.required),
    hide_days_without_hours: new FormControl(false, Validators.required),
  });

  private trackEvent: ReturnType<typeof getTrackEventFn>;

  public constructor(
    public dialogRef: DialogRef<AbsenceApprovalResult>,
    @Inject(DIALOG_DATA)
    private readonly data: DialogData,
    private absenceService: AbsenceService,
    private scheduleHelperService: ScheduleHelperService,
  ) {}

  public ngOnInit() {
    this.absence = this.data.absence;
    this.form.patchValue({
      roster_action: this.absence.roster_action,
      hide_days_without_hours: this.absence.hide_days_without_hours,
    });

    this.isOwnAbsence = this.data.isOwnAbsence;
    this.trackEvent = this.data.trackEvent;
  }

  public onBack() {
    this.dialogRef.close({
      action: 'back',
    });
  }

  public onSave() {
    this.saving = true;
    const updateData = {
      id: this.absence.id,
      startdate: this.absence.startdate,
      user_id: this.absence.user_id,
      absentee_option_id: this.absence.absentee_option_id,
      status: AbsenceStatus.APPROVED,
      roster_action: this.form.get('roster_action')?.value,
      hide_days_without_hours: this.form.get('hide_days_without_hours')?.value,
      // TODO temporary decision to always notify the employee on status change
      notify_employee: !this.isOwnAbsence && this.absence.canApprove,
    };
    void this.absenceService
      .save(updateData)
      .pipe(
        switchMap(() =>
          this.scheduleHelperService.updateSchedule({
            userId: this.absence.Employee.id,
            minDate: this.absence.startdate,
            maxDate: this.absence.enddate,
          }),
        ),
        take(1),
        finalize(() => (this.saving = false)),
      )
      .subscribe({
        next: () => {
          this.dialogRef.close({
            action: 'approved',
          });
        },
        error: (error) => {
          this.trackEvent('Absence Request Modal Failed');
          console.error(error);
        },
      });
  }
}

<div class="absolute right-[20px] top-[15px]">
  <a class="dialog-close-icon" (click)="ref.close()">
    <icon class="si modal-close__icon" svgIcon="close"></icon>
  </a>
</div>
<div class="mt-[3.5rem] flex justify-center plan-lock-container">
  <div class="flex flex-col items-center">
    @if (canManageAccount) {
      <span class="lock-header font-bold mb-5">{{ message?.[lockType]?.title | translate }}</span>
    } @else {
      <span class="lock-header font-bold mb-5">{{
        'Contact your administrator in order to upgrade your account' | translate
      }}</span>
    }

    <img class="mb-12 w-2/4" src="{{ 'assets/images/plan-lock.png' }}" />
    <span class="mb-12 font-light w-4/6 text-center">
      {{ message?.[lockType]?.text | translate }}
    </span>
    @if (message?.[lockType]?.knowledgeBaseUrl | knowledgeBaseArticleLink; as url) {
      <div class="mb-14 flex justify-between">
        <button (click)="goToUrl(url)" sb-button [color]="'outlineGreen'" [ngClass]="{ 'mr-5': canManageAccount }">
          {{ 'Learn more' | translate }}
        </button>
        @if (canManageAccount) {
          <button (click)="goToUpgradePage()" sb-button [color]="'green'" class="ml-5">
            {{ 'Upgrade now' | translate }}
          </button>
        }
      </div>
    }
  </div>
</div>

import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@sb/tooltip';
import { SummaryItemComponent } from '@sb/ui';

import { LocaleService } from '../../../+authenticated/shared/locale/locale.service';
import { AbsenceOptionUnit } from '../../../enums';
import { DecimalToDurationFormatPipe } from '../../../pipes/decimal-to-duration-format.pipe';
import { EnhancedAbsenceModel } from '../../../reducers/orm/absence/absence.model';
import { AvatarBadgeGroupComponent } from '../../sb-lib/avatar-badge-group/avatar-badge-group.component';

@Component({
  standalone: true,
  selector: 'absence-summary-item',
  imports: [
    AvatarBadgeGroupComponent,
    TranslateModule,
    LocaleDatePipe,
    DecimalToDurationFormatPipe,
    NgTemplateOutlet,
    TooltipModule,
    SummaryItemComponent,
    NgClass,
  ],
  templateUrl: './absence-summary-item.component.html',
  providers: [LocaleService],
})
export class AbsenceSummaryItemComponent {
  public AbsenceOptionUnit = AbsenceOptionUnit;

  public constructor(private router: Router) {}

  @Input()
  public absence: EnhancedAbsenceModel;

  @Input()
  public isOwnAbsence: boolean;

  @Input()
  public showStatus: boolean;

  @Input()
  public rangeClickShouldNavigate: boolean;

  // Display data related to date range and duration inline
  @Input()
  public inline: boolean;

  // Use smaller font sizes (used when used as a notification item in the sidebar)
  @Input()
  public compact: boolean;

  public redirectToSchedule(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.rangeClickShouldNavigate) {
      this.router.navigate(['schedule', 'employee', 'week', { date: this.absence.startdate }]);
    }
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ShiftWarningTooltipComponent } from './shift-warning-tooltip.component';

@Component({
  selector: 'shift-warning',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './shift-warning.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, ShiftWarningTooltipComponent],
})
export class ShiftWarningComponent {
  @Input()
  public absenceWarning: boolean;
  @Input()
  public doubleRegistration: boolean;
  @Input()
  public deviatesFromSchedule: boolean;
}

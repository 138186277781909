import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { LockType } from './sb-plan-lock-dialog.model';

export const planLockMessages = {
  [LockType.REPORT]: {
    title: _('Unlock your Shiftbase Reports'),
    text: _(
      'With the following upgrade you will have access to creating reports. This will give you insight into working hours, break times, plus minus hours, and overtime. All reports can be easily exported as either an Excel or CSV file.',
    ),
    knowledgeBaseUrl: 'reports-1',
  },
  [LockType.TIMESHEET]: {
    title: _('Unlock your Shiftbase Timesheet'),
    text: _(
      'With our Basic plan, you can view your working hours anytime, anywhere. Keep track of your working hours in a secure place, that you can access online, and integrate with your payroll workflow. Very easy right?',
    ),
    knowledgeBaseUrl: 'timesheet',
  },

  [LockType.DIARY]: {
    title: _('Unlock your Shiftbase Diary'),
    text: _(
      'With our Premium plan you can keep track of what happens at your workplace. This can be used for later reference. Very handy!',
    ),
    knowledgeBaseUrl: 'diary-1',
  },
  [LockType.OPEN_CLOSE_DAYS]: {
    title: _('Unlock the feature Open/Close Day'),
    text: _(
      'Gain control over your timesheet by utilizing the "Open/Close Day" feature in Shiftbase. Once activated, you can seamlessly incorporate opening and closing times for each workday. Only individuals with the certain permissions can make adjustments to worked hours after the day has been closed.',
    ),
    knowledgeBaseUrl: 'opening-and-closing-days',
  },
  [LockType.CLOCK_EMPLOYEE]: {
    title: _('Unlock the feature Clock Employee'),
    text: _(
      'Enhance your time-tracking capabilities with the "Clock Employee" feature. This functionality allows you to integrate a kiosk/terminal or use the Shiftbase app to conveniently clock in and out for your employees. Simplify your time tracking process with this efficient addition!',
    ),
    knowledgeBaseUrl: 'clocking-working-hours-with-desktop-app',
  },
  [LockType.ADD_REQUIRED_SHIFT]: {
    title: _('Unlock the feature Add Required Shifts'),
    text: _(
      'You can set an occupancy in Shiftbase. In this way you can check at a glance whether the schedule meets the desired requirements. If this is on, you will see a required shifts bar appear in the work schedule per department and team.',
    ),
    knowledgeBaseUrl: 'setting-up-required-shifts',
  },
  [LockType.ADD_OPEN_SHIFT]: {
    title: _('Unlock the feature Add Open Shifts'),
    text: _(
      'With this feature the planner can determine which employees are invited. These employees receive an invitation that can be accepted or rejected.',
    ),
    knowledgeBaseUrl: 'open-shifts',
  },
  [LockType.SEND_SCHEDULE]: {
    title: _('Unlock the feature Send Schedule'),
    text: _(
      'With this feature you can easily send the schedule to employees from Shiftbase. Once the schedule is sent, all selected employees receive an email. Employees who also have the mobile app installed will receive a push notification in addition to the email.',
    ),
    knowledgeBaseUrl: 'sending-schedules',
  },
  [LockType.PUBLISH_SCHEDULE]: {
    title: _('Unlock the feature Publishing schedule'),
    text: _(
      'With this feature all schedules are visible to employees by default. By default the schedule publishes all shifts 365 days ahead. You can change the number of days with this feature.',
    ),
    knowledgeBaseUrl: 'publishing-the-schedule',
  },
  [LockType.FORECAST]: {
    title: _('Unlock the feature Forecast'),
    text: _(
      'You can add the expected turnover to the schedule in Shiftbase. This allows you to see at a glance what the percentage of labor costs is in relation to the expected turnover.',
    ),
    knowledgeBaseUrl: 'adding-a-forecast',
  },
  [LockType.BUDGET]: {
    title: _('Unlock the feature Budget'),
    text: _(
      'You can add budgets to the schedule in Shiftbase. This allows you to see at a glance if the labor costs of the current roster are exceeding your budget. If the costs are outside the budget, this will be shown immediately.',
    ),
    knowledgeBaseUrl: 'setting-the-budget',
  },
  [LockType.AVAILABILITY]: {
    title: _('Unlock the feature Availibilty'),
    text: _(
      'With this feature, you can easily create a work schedule based on the availability of employees. This way, you always know that employees who are scheduled are actually available! In order for employees to be able to specify their availability, you need to give employees the appropriate permissions to do so.',
    ),
    knowledgeBaseUrl: 'availability-rules',
  },
  [LockType.ADD_ABSENCE]: {
    title: _('Unlock the feature Adding absence'),
    text: _(
      'With this feature you can register employee absence and in this overview you will find all information regarding the absence of the employee per year.',
    ),
    knowledgeBaseUrl: 'adding-absence',
  },
  [LockType.CALENDAR_SYNC]: {
    title: _('Unlock the feature Calendar synchronisation'),
    text: _(
      'With an upgrade it is possible to have the schedule in Shiftbase automatically synchronized with your personal calendar. This will make all the data from the schedule visible in the calendar.',
    ),
    knowledgeBaseUrl: 'calendar-synchronisation',
  },
  [LockType.PRINT_SCHEDULE]: {
    title: _('Unlock the feature Print schedule'),
    text: _(
      'You can easily print out the schedule from Shiftbase so that you can hang it up, for example. From here you can choose to print the schedule at departmental or employee level.',
    ),
    knowledgeBaseUrl: 'printing-the-work-schedule',
  },

  [LockType.MY_HOURS]: {
    title: _('Unlock the feature My hours'),
    text: _(
      'With this feature you can easily view your hours worked. You will immediately see how many shifts you worked in total during the selected period, how many hours you worked and, if you are authorized, how much salary those hours represent.',
    ),
    knowledgeBaseUrl: 'my-hours',
  },
  [LockType.MY_ABSENCE]: {
    title: _('Unlock the feature My absence'),
    text: _(
      'With this feature you will have insight into all the issues surrounding your absence as an employee. For example, you can see what your current leave balance is, request absences and view the status of old requests.',
    ),
    knowledgeBaseUrl: 'my-absence',
  },
  [LockType.MY_PLUS_MINUS]: {
    title: _('Unlock the feature My plus minus'),
    text: _(
      'Here you can see a clear overview of your plus minus balance. In this overview you can see the plus and minus hours per week and you can see the plus minus hours per day.',
    ),
    knowledgeBaseUrl: 'my-overtime',
  },

  [LockType.SEND_MESSAGE]: {
    title: _('Unlock the feature Send message'),
    text: _(
      'Send a message to multiple employees. With a bulk action, it is possible to perform an action for several employees at once.',
    ),
    knowledgeBaseUrl: 'bulk-action-send-message',
  },
  [LockType.CHANGE_SALARY]: {
    title: _('Unlock the feature Change salary'),
    text: _(
      'With this feature it is possible to change the salary for multiple employees at once. You can enter the hourly rate per employee',
    ),
    knowledgeBaseUrl: 'bulk-action-change-salary',
  },
  [LockType.CORRECT_BALANCES]: {
    title: _('Unlock the feature Correct balances'),
    text: _(
      'With this feature a correction can be made to the balances of employees. You can make corrections to overtime or time off balances by adding or subtracting hours. It is also possible to include these in the employee’s payroll.',
    ),
    knowledgeBaseUrl: 'bulk-action-correct-time-off-balance',
  },
  [LockType.EMPLOYEES]: {
    title: _('Unlock your employee settings'),
    text: _(
      'With our Basic plan, you can manage employee notifications, contract types, surcharges, and public holidays.',
    ),
    knowledgeBaseUrl: 'employees',
  },
  [LockType.EMPLOYEE_CUSTOM_FIELDS]: {
    title: _('Unlock the feature Employee custom fields'),
    text: _(
      'With our Premium plan, you can add additional fields to your employee details that Shiftbase does not have by default. From free input fields for text and numbers to dropdowns that you supply.',
    ),
    knowledgeBaseUrl: 'custom-fields-employees',
  },
  [LockType.EMPLOYEE_CONTRACT_TYPES]: {
    title: _('Unlock the feature Contract types'),
    text: _(
      'With our Basic plan, you can manage if your employees have a flex or fixed contract. If you need more than these two contract types switch to our Premium plan to add your more.',
    ),
    knowledgeBaseUrl: 'contract-types',
  },

  [LockType.OVERTIME]: {
    title: _('Unlock the feature Overtime'),
    text: _(
      'With our Premium plan, you can automatically apply a surcharge if an employee works more than a set amount of hours per day or week.',
    ),
    knowledgeBaseUrl: 'overtime-surcharge',
  },
  [LockType.SKILLS]: {
    title: _('Unlock the feature Skills'),
    text: _(
      'With our Premium plan, You can add skills to shifts and employees to match the perfect employee to a shift.',
    ),
    knowledgeBaseUrl: 'managing-skills',
  },

  [LockType.ABSENCE]: {
    title: _('Unlock the feature Absence'),
    text: _('With our Premium plan, you can manage absence policies, balances, types, and absence restrictions.'),
    knowledgeBaseUrl: 'absence-policies',
  },
  [LockType.TIME_TRACKING]: {
    title: _('Unlock the feature Time tracking'),
    text: _(
      'With our Basic plan, you can manage if a department will make use of surcharges, meals, and kilometre registration.',
    ),
    knowledgeBaseUrl: 'timesheet-settings',
  },
  [LockType.TIME_TRACKING_NOTIFICATION]: {
    title: _('Unlock the feature Time tracking notification'),
    text: _('With our Basic plan, you can receive notifications when employees forget to clock in or out.'),
    knowledgeBaseUrl: 'managing-notifications',
  },
  [LockType.SCHEDULE_NOTIFICATION]: {
    title: _('Unlock the feature Schedule notification'),
    text: _(
      'With our Basic plan, you can receive notifications when employees request to exchange shifts or when employees submit a time-off request.',
    ),
    knowledgeBaseUrl: 'managing-notifications',
  },
  [LockType.TIME_TRACKING_CUSTOM_FIELDS]: {
    title: _('Unlock the feature Time tracking custom fields'),
    text: _(
      'With our Premium plan, you can add additional fields to your Shifts, Schedules, and Timesheets that Shiftbase does not have by default.  From free input fields for text and numbers to dropdowns that you supply.',
    ),
    knowledgeBaseUrl: 'custom-fields-schedule-timesheet',
  },
  [LockType.CLOCKING]: {
    title: _('Unlock the feature Clocking'),
    text: _('With our Basic plan, you can manage automatic rules for rounding clock times '),
    knowledgeBaseUrl: 'punch-clock-management',
  },
  [LockType.CLOCK_BOUNDARIES]: {
    title: _('Unlock the feature Clock boundaries'),
    text: _(
      'With our Premium plan, you can set where your employees can clock in based on either their location or IP address.',
    ),
    knowledgeBaseUrl: 'clock-boundaries',
  },
  [LockType.BREAK_RULES]: {
    title: _('Unlock the feature Break rules'),
    text: _(
      'Take control of breaks with this feature. Set rules, durations, and restrictions effortlessly, streamlining break management for efficient time utilization within your company.',
    ),
    knowledgeBaseUrl: 'break-rules',
  },
  [LockType.KIOSK]: {
    title: _('Unlock the feature Kiosk'),
    text: _(
      'With our Premium plan, you can add a kiosk where employees can clock in for their shifts. Very handy if you want to clock time from a central point in your company.',
    ),
    knowledgeBaseUrl: 'kiosk-settings',
  },

  [LockType.SCHEDULE_CUSTOM_FIELDS]: {
    title: _('Unlock the feature Schedule custom fields'),
    text: _(
      'With our Premium plan, you can receive notifications when employees request to exchange shifts or when employees submit a time-off request.',
    ),
    knowledgeBaseUrl: 'custom-fields-schedule-timesheet',
  },

  //can be removed after tmp_starter_to_basic is removed
  [LockType.OLD_SCHEDULE_NOTIFICATION]: {
    title: _('Unlock the feature Schedule notification'),
    text: _(
      'With our Starter plan, you can receive notifications when employees request to exchange shifts or when employees submit a time-off request.',
    ),
    knowledgeBaseUrl: 'managing-notifications',
  },
  [LockType.OLD_TIMESHEET]: {
    title: _('Unlock your Shiftbase Timesheet'),
    text: _(
      'With our Starter plan, you can view your working hours anytime, anywhere. Keep track of your working hours in a secure place, that you can access online, and integrate with your payroll workflow. Very easy right?',
    ),
    knowledgeBaseUrl: 'timesheet',
  },
  [LockType.OLD_EMPLOYEES]: {
    title: _('Unlock your employee settings'),
    text: _(
      'With our Starter plan, you can manage employee notifications, contract types, surcharges, and public holidays.',
    ),
    knowledgeBaseUrl: 'employees',
  },
  [LockType.OLD_TIME_TRACKING]: {
    title: _('Unlock the feature Time tracking'),
    text: _(
      'With our Starter plan, you can manage if a department will make use of surcharges, meals, and kilometre registration.',
    ),
    knowledgeBaseUrl: 'timesheet-settings',
  },
};

import { DestroyRef, Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AbsenceStatus, EnhancedAbsenceModel } from '@reducers/orm/absence/absence.model';
import { getEnhancedAbsenceById } from '@reducers/orm/absence/absence.service';
import { SbDialogService } from '@sb/ui';
import { take, tap } from 'rxjs';

import { Features } from '../../../enums';
import { TrackingService } from '../../../services/tracking.service';
import { FeatureService } from '../../../startup/feature.service';
import { AbsenceApprovalComponent, AbsenceApprovalResult } from './absence-approval.component';
import { AbsenceEditComponent, AbsenceEditDialogResult } from './absence-edit.component';
import { AbsenceRequestComponent, AbsenceRequestDialogResult } from './absence-request.component';
import { getTrackEventFn } from './absence-request.helper';
import { AbsenceRequestAction, AbsenceRequestOriginator } from './absence-request.models';

@Directive({
  standalone: true,
  selector: '[appAbsenceRequest]',
})
export class AbsenceRequestDirective implements OnInit {
  @Input()
  public set appAbsenceRequest(context: {
    absenceId: string;
    isOwnAbsence?: boolean;
    action?: AbsenceRequestAction;
    originator: AbsenceRequestOriginator;
  }) {
    this.absenceId = context.absenceId;
    this.originator = context.originator;
    if (context.isOwnAbsence !== undefined) {
      this.isOwnAbsence = context.isOwnAbsence;
    }
    if (context.action) {
      this.action = context.action;
    }
  }

  @HostBinding('class')
  public class: string;

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent) {
    event.stopPropagation();
    if (!this.newAbsenceApprovalEnabled) {
      // Fallback to "edit" action
      if (this.absence?.canEdit) {
        this.router.navigate(['', { outlets: { modal: ['absentee-days-request', this.absenceId] } }]);
      }
      return;
    }

    switch (this.action) {
      case 'approve':
        this.openApprovalModal();
        break;
      case 'edit':
        this.openEditModal();
        break;
      default:
        this.openRequestModal();
    }
  }

  private absenceId: string;
  private absence: EnhancedAbsenceModel;
  private isOwnAbsence = false;
  private action: AbsenceRequestAction = 'view';
  private originator: AbsenceRequestOriginator;
  private newAbsenceApprovalEnabled: boolean;

  public constructor(
    private dialog: SbDialogService,
    private store: Store,
    private feature: FeatureService,
    private router: Router,
    private trackingService: TrackingService,
    private destroyRef: DestroyRef,
  ) {
    this.newAbsenceApprovalEnabled = this.feature.isFeatureActivated(Features.TMP_NEW_ABSENCE_APPROVAL);
    this.setClass();
  }

  public ngOnInit(): void {
    this.setAbsence();
  }

  private openRequestModal() {
    const action = 'view';
    const trackEvent = getTrackEventFn(this.trackingService, this.originator, action);

    const dialog = this.dialog.open(AbsenceRequestComponent, {
      data: {
        absence: this.absence,
        isOwnAbsence: this.isOwnAbsence,
        trackEvent,
      },
    });

    trackEvent('Absence Request Modal Opened');

    void dialog.closed.pipe(take(1)).subscribe({
      next: (result: AbsenceRequestDialogResult) => {
        switch (result?.action) {
          case 'deleted':
          case 'statusChanged':
          case 'markAsReturned':
            trackEvent('Absence Request Modal Completed');
            break;
          case 'approve':
            trackEvent('Absence Request Modal Dismissed');
            this.openApprovalModal();
            break;
          case 'edit':
            trackEvent('Absence Request Modal Dismissed');
            this.openEditModal();
            break;
          default:
            trackEvent('Absence Request Modal Dismissed');
        }
      },
    });
  }

  private openApprovalModal() {
    const action = 'approve';
    const trackEvent = getTrackEventFn(this.trackingService, this.originator, action);

    const dialog = this.dialog.open(AbsenceApprovalComponent, {
      data: {
        absence: this.absence,
        isOwnAbsence: this.isOwnAbsence,
        trackEvent,
      },
    });

    trackEvent('Absence Request Modal Opened');

    void dialog.closed.pipe(take(1)).subscribe({
      next: (result: AbsenceApprovalResult) => {
        switch (result?.action) {
          case 'approved':
            trackEvent('Absence Request Modal Completed');
            break;
          case 'back':
            trackEvent('Absence Request Modal Dismissed');
            this.openRequestModal();
            break;
          default:
            trackEvent('Absence Request Modal Dismissed');
            this.dialog.closeAll();
        }
      },
    });
  }

  private openEditModal() {
    const action = 'edit';
    const trackEvent = getTrackEventFn(this.trackingService, this.originator, action);

    const dialog = this.dialog.open(AbsenceEditComponent, {
      data: {
        absence: this.absence,
        trackEvent,
      },
    });

    trackEvent('Absence Request Modal Opened');

    void dialog.closed.pipe(take(1)).subscribe({
      next: (result: AbsenceEditDialogResult) => {
        switch (result?.action) {
          case 'edited':
            trackEvent('Absence Request Modal Completed');
            if (result.absenceStatus === AbsenceStatus.PENDING) {
              this.openRequestModal();
            }
            break;
          case 'back':
            trackEvent('Absence Request Modal Dismissed');
            this.openRequestModal();
            break;
          default:
            trackEvent('Absence Request Modal Dismissed');
            this.dialog.closeAll();
        }
      },
    });
  }

  private setAbsence() {
    void this.store
      .select(getEnhancedAbsenceById(this.absenceId))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (absence: EnhancedAbsenceModel) => {
          this.absence = absence;
          this.setClass();
        },
      });
  }

  private setClass() {
    // Once TMP_NEW_ABSENCE_APPROVAL is removed this method can be removed
    // and class defined as private readonly class = 'cursor-pointer';
    this.class = this.newAbsenceApprovalEnabled || this.absence?.canEdit ? 'cursor-pointer' : 'cursor-default';
  }
}

import { NgModule } from '@angular/core';

import { AbsenceRequestDirective } from '../../../+modals/employee/absence-request/absence-request.directive';
import { SharedModule } from '../../../shared/shared.module';
import { ShiftWarningComponent } from './shift-warning/shift-warning.component';
import { TimesheetTeamDropdownComponent } from './timesheet/timesheet-team-dropdown/timesheet-team-dropdown.component';
import { TimesheetResolver } from './timesheet/timesheet.resolver';

const standalone = [ShiftWarningComponent, AbsenceRequestDirective];

@NgModule({
  imports: [SharedModule, ...standalone],
  declarations: [TimesheetTeamDropdownComponent],
  exports: [TimesheetTeamDropdownComponent, ...standalone],
  providers: [TimesheetResolver],
})
export class SharedTimesheetModule {}

import { Directive, HostBinding, Input } from '@angular/core';
import { AbsenceStatus, EnhancedAbsenceModel } from '@app/reducers/orm/absence/absence.model';
import { BadgeIconComponent } from '@sb/ui';
import { clsx } from 'clsx';

@Directive({
  selector: 'sb-badge-icon[absenceBadgeIcon]',
  standalone: true,
})
export class AbsenceBadgeIconDirective {
  @HostBinding('class')
  public classes = '';

  @HostBinding('style')
  public styles = {};

  @Input({ alias: 'absenceBadgeIcon', required: true })
  public set absenceOption(absence: EnhancedAbsenceModel) {
    const absenceType = absence.absenteeOption;
    const isPending = absence.status === AbsenceStatus.PENDING;
    this.component.name = absenceType.icon;
    this.classes = clsx({
      /* eslint-disable @typescript-eslint/naming-convention */
      'text-white': !isPending && absenceType.color_is_dark,
      'text-word-mark-800': isPending || !absenceType.color_is_dark,
      'border-2 border-solid': true,
      /* eslint-enable @typescript-eslint/naming-convention */
    });

    this.styles = {
      backgroundColor: isPending ? 'white' : absenceType.color,
      borderColor: absenceType.color,
    };
  }

  public constructor(private component: BadgeIconComponent) {}
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { isValid } from 'date-fns';
import { Observable, of as observableOf } from 'rxjs';
import { combineLatest, first, map, tap } from 'rxjs/operators';

import { AppState } from '../../../../reducers/index';
import { TimesheetFilterState } from '../../../../reducers/page-filters/page-filters.model';
import { getTimesheetFilters, PageFiltersService } from '../../../../reducers/page-filters/page-filters.service';
import { format, getPeriod, parseDate } from '../../../../shared/date.helper';
import { lazySelect } from '../../../../shared/lazy-select.observable';
import { TimesheetHelperService } from '../timesheet-helper.service';

@Injectable()
export class TimesheetResolver {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private pageFiltersService: PageFiltersService,
    private timesheetHelperService: TimesheetHelperService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.activeDate(route).pipe(
      tap((date) => {
        const period = getPeriod(route.data.periodType, date);

        this.pageFiltersService.setFilter('timesheet', 'period', {
          minDate: format(period.start, 'yyyy-MM-dd'),
          maxDate: format(period.end, 'yyyy-MM-dd'),
        });
      }),
      first(),
    );
  }

  private activeDate(route: ActivatedRouteSnapshot): Observable<Date> {
    return observableOf(route.params).pipe(
      map((params: Params) => params['date']),
      combineLatest(
        this.store.pipe(
          lazySelect(getTimesheetFilters),
          map((filter: TimesheetFilterState) =>
            filter && filter.period ? filter.period.minDate : format(new Date(), 'yyyy-MM-dd'),
          ),
        ),
      ),
      map(([routeDate, selectedDate]) => (!routeDate ? selectedDate : routeDate)),
      map((selectedDate) => {
        const parsed = parseDate(selectedDate);

        return isValid(parsed) ? parsed : new Date();
      }),
    );
  }
}

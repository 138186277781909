/* eslint-disable max-lines */
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormArray,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AbsenteePermissionDirective } from '@app/+authenticated/shared/absentee-permission.directive';
import { PermissionDirective } from '@app/+authenticated/shared/permission.directive';
import { DateEndOfYearPipe } from '@app/pipes/date-end-of-year.pipe';
import { DecimalToDurationFormatPipe } from '@app/pipes/decimal-to-duration-format.pipe';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { KnowledgeBaseArticleLinkModule } from '@app/pipes/knowledge-base-article-link.module';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { absencePolicyLoadAllRequest } from '@app/reducers/orm/absence-policy/absence-policy.action';
import { AbsencePolicyModel } from '@app/reducers/orm/absence-policy/absence-policy.model';
import { selectPolicyById } from '@app/reducers/orm/absence-policy/absence-policy.selectors';
import { EmployeeModel } from '@app/reducers/orm/employee/employee.model';
import { ContentStateComponent } from '@app/shared/content-state/content-state.component';
import { DatePipe } from '@app/shared/sb-lib/calendar/pipes/date.pipe';
import { SbCalendarModule } from '@app/shared/sb-lib/calendar/sb-calendar.module';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { getAccountSubscription } from '@reducers/account/account.service';
import {
  getEmployeeTeamDepartmentsWithoutFlexpool,
  getPermissionState,
  hasPermission,
} from '@reducers/auth/permission.helper';
import { AppState } from '@reducers/index';
import { AbsenceRestrictionService } from '@reducers/orm/absence-restriction/absence-restriction.service';
import { checkAbsenceConflict } from '@reducers/orm/absence/absence.helper';
import {
  AbsenceConflictOptions,
  AbsenceExpectedRequest,
  AbsenceExpectedResponse,
  AbsenceStatus,
  AbsenteeDay,
  AbsenteeDaysData,
  EnhancedAbsenceModel,
} from '@reducers/orm/absence/absence.model';
import { AbsenceService, getUserAbsenceForPeriod } from '@reducers/orm/absence/absence.service';
import { AbsenteeOptionModel } from '@reducers/orm/absentee-option/absentee-option.model';
import {
  getAbsenteeOptionById,
  getPermittedAbsenteeOptions,
} from '@reducers/orm/absentee-option/absentee-option.service';
import { EmployeeService } from '@reducers/orm/employee/employee.service';
import { getTimeOffBalancesForAbsencePolicy } from '@reducers/orm/time-off-balance/time-off-balance.selectors.ts';
import { TooltipModule } from '@sb/tooltip';
import {
  ButtonComponent,
  CheckboxComponent,
  DIALOG_DATA,
  DialogRef,
  IconComponent,
  SbDialogSharedModule,
  SelectComponent,
  SelectItem,
} from '@sb/ui';
import { isWeekend, parse } from 'date-fns';
import isEmpty from 'lodash-es/isEmpty';
import keyBy from 'lodash-es/keyBy';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  pairwise,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';

import { PlanType } from '../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { ScheduleHelperService } from '../../../+authenticated/+schedule/shared/schedule-helper.service';
import { AbsenceCalculationType, AbsenceOptionUnit, Features } from '../../../enums';
import { fieldChangeWithStart, handleSubmitError } from '../../../forms/form.helper';
import { ValidationService } from '../../../forms/validation.service';
import { DecimalToTimePipe } from '../../../pipes/decimal-to-time.pipe';
import { dayList, format, timeToDecimal } from '../../../shared/date.helper';
import { LaddaDirective } from '../../../shared/ladda/ladda.directive';
import { SbFormFieldComponent } from '../../../shared/sb-lib/forms/sb-form-field.component';
import {
  hasAtleastSubscriptionPlan,
  SubscriptionPlanDirective,
} from '../../../shared/subscription-plan/subscription-plan.directive';
import { FeatureService } from '../../../startup/feature.service';
import { AbsenceDayOption } from '../employee-absentee-days/employee-absentee-days.enum';
import { totalDays, totalHours, totalWaitHours } from '../employee-absentee-days/employee-absentee-days.helper';
import { DayValue } from '../employee-absentee-days/employee-absentee-days.model';
import {
  getAbsenceDayOption,
  getPeriod,
  getPeriodControlValue,
  getTrackEventFn,
  isPeriodValid,
} from './absence-request.helper';
import { AbsenceBalanceStatsComponent } from './employee-information/absence-balance-stats.component';
import { AbsenceExpectedHoursComponent } from './employee-information/absence-expected-hours.component';
import { AbsenceRequestDayControlsComponent } from './form-fields/absence-request-day-controls.component';
import { AbsenceRequestPeriodFieldComponent } from './form-fields/absence-request-period-field.component';

const DEFAULT_DAY_TIME = '12:00';

export interface AbsenceEditDialogResult {
  action: 'back' | 'edited';
  absenceStatus?: AbsenceStatus;
}

interface DialogData {
  absence: EnhancedAbsenceModel;
  trackEvent: ReturnType<typeof getTrackEventFn>;
}

@Component({
  selector: 'absence-edit',
  templateUrl: './absence-edit.component.html',
  providers: [DecimalToTimePipe],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    SbCalendarModule,
    KnowledgeBaseArticleLinkModule,
    ReactiveFormsModule,
    SbDialogSharedModule,
    // Directives
    SubscriptionPlanDirective,
    AbsenteePermissionDirective,
    PermissionDirective,
    LaddaDirective,
    // Pipes
    FeatureFlagPipe,
    DateEndOfYearPipe,
    DatePipe,
    TranslationParamsPipe,
    DecimalToDurationFormatPipe,
    DecimalToTimePipe,
    // Components
    ContentStateComponent,
    SelectComponent,
    TooltipModule,
    ButtonComponent,
    SbFormFieldComponent,
    CheckboxComponent,
    AbsenceRequestDayControlsComponent,
    AbsenceExpectedHoursComponent,
    AbsenceBalanceStatsComponent,
    AbsenceRequestPeriodFieldComponent,
    IconComponent,
  ],
})
export class AbsenceEditComponent implements OnInit {
  public loading = false;
  public absenteeDaysData: Record<string, AbsenteeDaysData> = {};
  public showStartTimeColumn: boolean;

  public Features = Features;
  public PlanType = PlanType;

  // TODO currently we rely on handleSubmitError to handle validation errors
  // this thing can only deal with untyped forms.
  // Eventually we should refactor this to use typed forms
  public form = new UntypedFormGroup({
    absentee_option_id: new UntypedFormControl('', [Validators.required]),
    period: new UntypedFormControl(
      [null, null],
      [Validators.required, ValidationService.rangePickerPeriodValidator(true), ValidationService.dateValidator],
    ),
    note: new UntypedFormControl(''),
    open_ended: new UntypedFormControl({ value: false, disabled: true }),
    wait_days: new UntypedFormControl(0),
  });

  public waitHoursRange = Array.from({ length: 6 }, (_, i) => ({ text: `${i}`, value: i }));
  public totalWaitHours: number;

  private daySubscription = new Subscription();
  private dayCalculationSubject = new BehaviorSubject<{ state: 'absenteeDays' | 'hours' | 'stop' }>({ state: 'stop' });

  public absenteeOptions$: Observable<AbsenteeOptionModel[]>;

  public selectedEmployee: EmployeeModel;
  public loadingHours = false;
  public loadingDays = false;

  public hasWaitHours: boolean;

  public dayControls: UntypedFormGroup[];

  public expectedHours: AbsenceExpectedResponse;
  public absenceConflictsForPeriod;

  public hasAtleastStarter: boolean;
  public hasAtleastPremium: boolean;
  public absenceRestrictionConflicts = {};
  public hasAbsenceRestrictionConflict: boolean;
  public canBypassPolicy: boolean;
  private timeOffBalanceOptions$: BehaviorSubject<SelectItem<string>[]> = new BehaviorSubject<SelectItem<string>[]>(
    undefined,
  );
  public timeOffBalanceOptions: SelectItem<string>[];

  public expectedHoursReceived: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private checkPeriodConflict: Subject<undefined> = new Subject<undefined>();

  public canViewTimeOffBalances: boolean;
  public canApprove: boolean;
  public canUpdateTimeOffBalances: boolean;

  public includeTimeOffBalanceIds: string[] = [];
  private fetchTimeOffBalances$ = new BehaviorSubject<string>(null);

  private absencePolicyId$ = new BehaviorSubject<string>(null);
  private absencePolicy$: Observable<AbsencePolicyModel>;
  private allPermittedAbsenceTypes$: Observable<AbsenteeOptionModel[]>;

  public selectedAbsenceType: AbsenteeOptionModel;
  public totalDays: number;
  public totalHours: number;

  public readonly absenceOptionUnit = AbsenceOptionUnit;
  public readonly absenceDayOption = AbsenceDayOption;

  public absence: EnhancedAbsenceModel;
  public mappedAbsenceTypes: SelectItem[];

  private trackEvent: ReturnType<typeof getTrackEventFn>;

  public constructor(
    @Inject(DIALOG_DATA)
    private readonly data: DialogData,
    public dialogRef: DialogRef<AbsenceEditDialogResult>,
    private absenceService: AbsenceService,
    private employeeService: EmployeeService,
    private store: Store<AppState>,
    private scheduleHelperService: ScheduleHelperService,
    private decimalToTimePipe: DecimalToTimePipe,
    private absenceRestrictionService: AbsenceRestrictionService,
    private readonly destroyRef: DestroyRef,
    private featureService: FeatureService,
  ) {}

  public ngOnInit() {
    this.absence = this.data.absence;

    this.allPermittedAbsenceTypes$ = this.store.select(getPermittedAbsenteeOptions(this.absence.absentee_option_id));

    this.absencePolicy$ = this.absencePolicyId$.pipe(switchMap((id) => this.store.select(selectPolicyById(id))));

    this.absenteeOptions$ = combineLatest([this.allPermittedAbsenceTypes$, this.absencePolicy$]).pipe(
      map(([allOptions, absencePolicy]) =>
        allOptions.filter(
          (option) =>
            option.id === this.absence?.absentee_option_id ||
            absencePolicy?.configuration?.find((config) => config.absenceTypeId === option.id),
        ),
      ),
    );

    this.store.dispatch(absencePolicyLoadAllRequest());
    this.subscribeToPolicy();
    this.subscribeToOptionsChange();
    this.subscribeToTimeOffBalances();

    void this.store
      .pipe(select(getAccountSubscription), takeUntilDestroyed(this.destroyRef))
      .subscribe((accountSubscription) => {
        this.hasAtleastStarter = hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription);
        this.hasAtleastPremium = hasAtleastSubscriptionPlan(PlanType.PREMIUM, accountSubscription);
      });

    this.checkForAbsenceRestrictionConflict();
    this.subscribeToWaitHoursChange();
    this.processAbsence();
    this.subscribeToForm();

    this.trackEvent = this.data.trackEvent;
  }

  public subscribeToWaitHoursChange() {
    void fieldChangeWithStart(this.form.get('wait_days'))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.setTotalWaitHours();
      });
  }

  private subscribeToTimeOffBalances() {
    void this.timeOffBalanceOptions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((options) => {
      this.timeOffBalanceOptions = options;
    });
  }

  private subscribeToOptionsChange() {
    void this.absenteeOptions$
      .pipe(
        filter((options) => options?.length > 0),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (options) => {
          this.mappedAbsenceTypes = options.map((option) => ({
            text: option.option,
            value: option.id,
          }));
          const absenceIdCtrl = this.form.get('absentee_option_id');
          // Patch the value only if a value was already selected and its no longer available in the policy due to period change
          if (absenceIdCtrl.value && !options.find((option) => option.id === absenceIdCtrl.value)) {
            absenceIdCtrl.patchValue(options[0].id);
            this.dayCalculationSubject.next({ state: 'absenteeDays' });
          }
        },
      });
  }

  private checkForAbsenceRestrictionConflict() {
    void combineLatest([
      fieldChangeWithStart(this.form.get('period')),
      fieldChangeWithStart(this.form.get('absentee_option_id')),
    ])
      .pipe(
        filter(() => this.isValidForAbsenceCheck()),
        map(([period, absenceType]) => {
          period = getPeriod(period);
          return {
            start_date: period[0],
            end_date: period[1],
            user_id: this.absence.Employee.id,
            absentee_option_id: absenceType,
            absentee_id: this.absence.id,
          };
        }),
        debounceTime(200),
        switchMap((requestData) => this.absenceRestrictionService.validateAbsence(requestData)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((absenceRestrictionConflicts) => {
        const conflicts = {};
        absenceRestrictionConflicts?.forEach((conflict) => {
          conflicts[conflict] = true;
        });
        this.absenceRestrictionConflicts = conflicts;
        this.hasAbsenceRestrictionConflict = absenceRestrictionConflicts?.length > 0;
      });
  }

  private setDateRange() {
    this.form
      .get('period')
      .patchValue([this.absence.startdate, this.absence.enddate], { onlySelf: true, emitEvent: false });
  }

  private setWaitHours() {
    const waitHourDays: number = Object.keys(this.absence?.AbsenteeDay ?? {}).reduce((acc: number, curr: string) => {
      const day: AbsenteeDay = this.absence.AbsenteeDay[curr];
      if (parseFloat(day.wait_hours) > 0) {
        return acc + 1;
      }
      return acc;
    }, 0);

    if (waitHourDays > 0) {
      this.form.get('wait_days').setValue(waitHourDays);
    }
  }

  private processAbsenceType() {
    void this.allPermittedAbsenceTypes$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((absenteeOptions) => {
      const absenteeOption = absenteeOptions.find((option) => option.id === this.absence['absentee_option_id']);
      if (absenteeOption) {
        this.hasWaitHours = absenteeOption.has_wait_hours;
      }
    });
  }

  private processAbsence() {
    this.setWaitHours();
    this.setDateRange();

    this.setExpectedHours();

    this.includeTimeOffBalanceIds = this.getBalancesInUse(Object.values(this.absence?.AbsenteeDay ?? {}));

    this.fetchTimeOffBalances$.next(this.absence['absentee_option_id']);

    const patchList = ['absentee_option_id', 'hours', 'note'];
    if (this.featureService.isFeatureActivated(Features.TMP_OPEN_ENDED_ABSENCES)) {
      patchList.push('open_ended');
    }

    const patch = pick(this.absence, patchList);
    this.form.patchValue(patch, { onlySelf: true, emitEvent: false });

    if (this.featureService.isFeatureActivated(Features.TMP_OPEN_ENDED_ABSENCES) && this.absence.open_ended) {
      this.updatePeriodValue(true);
      this.listenToAbsenceTypeChanges();
      this.listenToOpenEndedChanges();
    }

    // When editing an absence use the existing absentee days
    // to populate the form. Only retrieve calculated days
    // when the user explicitly requests these.
    if (this.absence.AbsenteeDay) {
      const days = Object.values(this.absence.AbsenteeDay);
      void this.timeOffBalanceOptions$
        .pipe(
          filter((options) => !!options),
          take(1),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.buildAbsenceDaysFormArray(days);
        });
    }

    this.processAbsenceType();
  }

  private listenToAbsenceTypeChanges() {
    void this.form
      .get('absentee_option_id')
      .valueChanges.pipe(
        switchMap((selected) => this.store.select(getAbsenteeOptionById(selected))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((absenceType) => {
        const isOpenEnded = this.form.get('open_ended').value;

        if (isOpenEnded && !absenceType.allow_open_ended) {
          this.form.get('open_ended').setValue(false);
          // Moving from not allowed open ended to allowed open ended
          // and the absence is open ended
        } else if (!isOpenEnded && absenceType.allow_open_ended && this.absence.open_ended) {
          this.form.get('open_ended').setValue(true);
        }
      });
  }

  private listenToOpenEndedChanges() {
    void this.form
      .get('open_ended')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((openEnded) => this.updatePeriodValue(openEnded));
  }

  private updatePeriodValue(openEnded: boolean) {
    const { newValue, emitEvent } = getPeriodControlValue(openEnded, this.form.get('period').value);

    this.form.get('period').setValue(newValue, { emitEvent });
  }

  private setExpectedHours() {
    if (isPeriodValid(this.form.get('period')) && this.hasAtleastStarter && this.absence.Employee.id) {
      void this.absenceService
        .getExpected({
          user_id: this.absence.Employee.id,
          startdate: this.absence.startdate,
          enddate: this.absence.enddate,
          absence_type_id: this.absence.absentee_option_id,
        })
        .subscribe({
          next: (response) => {
            this.expectedHours = response;
            this.expectedHoursReceived.next(true);
          },
        });
    }
  }

  private subscribeToForm() {
    this.subscribeToPermissions();

    this.subscribeToDayCalculation();
    this.subscribeToPeriod();
    this.subscribeToPeriodConflict();
    this.subscribeToAbsenceConflict();

    this.subscribeToOptionChange();
  }

  private subscribeToOptionChange() {
    void combineLatest([this.fetchTimeOffBalances$, this.absencePolicy$])
      .pipe(
        filter(([optionId, absencePolicy]) => !!optionId || !!absencePolicy),
        switchMap(([optionId, absencePolicy]) =>
          this.store.select(
            getTimeOffBalancesForAbsencePolicy(absencePolicy?.id, optionId, this.includeTimeOffBalanceIds),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((timeOffBalances) => {
        this.timeOffBalanceOptions$.next(timeOffBalances);
      });
  }

  private getBalancesInUse(absenteeDays: AbsenteeDay[]): string[] {
    return [...new Set(absenteeDays.map((day: AbsenteeDay) => day.time_off_balance_id))];
  }

  // eslint-disable-next-line max-lines-per-function
  private subscribeToPermissions() {
    void combineLatest(
      [
        this.store.pipe(select(getPermissionState), distinctUntilChanged()),
        this.store.pipe(select(getEmployeeTeamDepartmentsWithoutFlexpool)),
        fieldChangeWithStart(this.form.get('absentee_option_id')).pipe(
          switchMap((selected) => this.store.select(getAbsenteeOptionById(selected))),
        ),
      ],
      (permissionState, employeeTeamDepartments, selectedType) => {
        const userId = this.absence.Employee.id;
        this.selectedAbsenceType = selectedType;
        const departments = employeeTeamDepartments[userId];
        return { permissionState, userId, departments, selectedType };
      },
    )
      .pipe(
        map((data) => {
          const { permissionState, userId, departments, selectedType } = data;

          const isSelf = permissionState.userId === userId;
          if (isSelf) {
            this.canViewTimeOffBalances = hasPermission(
              { permissions: ['View own time off balances'], userId, departments },
              permissionState,
            );
            this.canApprove = hasPermission(
              { permissions: ['Approve own absentee'], userId, departments },
              permissionState,
            );
          } else {
            this.canViewTimeOffBalances = hasPermission(
              { permissions: ['View time off balances'], userId, departments },
              permissionState,
            );
            this.canApprove = hasPermission(
              { permissions: ['Approve absentee'], userId, departments },
              permissionState,
            );
          }

          this.canUpdateTimeOffBalances = this.canApprove && this.canViewTimeOffBalances;

          return { isSelf, canApprove: this.canApprove, selectedType };
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data) => {
        const { isSelf, canApprove, selectedType } = data;

        this.canBypassPolicy = canApprove;

        if (isSelf || canApprove) {
          this.form.get('note').enable();
        } else {
          this.form.get('note').disable();
        }

        if (selectedType?.has_wait_hours) {
          if (canApprove) {
            this.hasWaitHours = true;
          } else {
            this.hasWaitHours = false;
          }
        } else {
          this.hasWaitHours = false;
        }
      });
  }

  private subscribeToDayCalculation() {
    void this.dayCalculationSubject
      .pipe(
        filter(({ state }) => state === 'absenteeDays' || state === 'hours'),
        filter(() => isPeriodValid(this.form.get('period')) && this.form.get('absentee_option_id').valid),
        switchMap(({ state }) => {
          if (!this.hasAtleastStarter) {
            return of(null);
          }

          const period = getPeriod(this.form.get('period').value);

          const requestData: AbsenceExpectedRequest = {
            user_id: this.absence.Employee.id,
            startdate: period[0],
            enddate: period[1],
            absence_type_id: this.form.get('absentee_option_id').value,
          };

          this.loadingDays = state === 'absenteeDays';
          this.loadingHours = state === 'hours';

          // when expected throws an error, we want to return null to allow absence options to patch to a valid option
          return this.absenceService.getExpected(requestData).pipe(catchError(() => of(null)));
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (response) => {
          const { state } = this.dayCalculationSubject.getValue();

          if (!response) {
            this.buildAbsenceDaysFormArray([]);
            this.loadingDays = false;
          } else {
            this.expectedHours = response;
            this.expectedHoursReceived.next(true);

            if (response.days) {
              const absenceDays = Object.values(response.days);
              this.buildAbsenceDaysFormArray(absenceDays);
              // TODO: Check if some value actually changes in the form
              this.form.markAsDirty();
              this.loadingDays = false;
            }
          }

          if (state === 'hours') {
            this.loadingHours = false;
          }

          this.dayCalculationSubject.next({ state: 'stop' });
        },
      });
  }

  private buildAbsenceDaysFormArray(days) {
    const { state } = this.dayCalculationSubject.getValue();

    const daysControl: FormArray<UntypedFormGroup> = new UntypedFormArray([]);
    const existingDays = this.form.contains('days') ? this.form.get('days').value : [];

    const period = getPeriod(this.form.get('period').value);
    const periodDays = dayList(period[0], period[1]);

    const retrievedDays = keyBy(days, 'date');
    this.daySubscription.unsubscribe();
    this.daySubscription = new Subscription();
    this.showStartTimeColumn = false;
    this.absenteeDaysData = {};

    periodDays.forEach((periodDay) => {
      let dayControl: UntypedFormGroup;
      let dayValue: DayValue;

      // If the user has already provided values for a day, existingDay values will be used to populate the form.
      // If the user has not provided values, but a day is retrieved / suggested, this value will be used to populate the form.
      // If both days do not exist, the default day will be used to populate the form.
      const defaultDay: DayValue = {
        date: periodDay,
        hours: 0,
        partial_day: false,
        start_time: '00:00:00',
        isWeekend: isWeekend(parse(periodDay, 'yyyy-MM-dd', new Date())),
        wait_hours: '0.00000',
        time_off_balance_id: null,
        day_time: DEFAULT_DAY_TIME,
      };

      dayValue = defaultDay;

      const retrievedDay = retrievedDays[periodDay];
      if (retrievedDay) {
        dayValue = {
          ...dayValue,
          ...retrievedDay,
        };
        if (this.isRequestInDays && retrievedDay.partial_day) {
          dayValue.day_time = retrievedDay.from_time || retrievedDay.until_time || DEFAULT_DAY_TIME;
        }
      }

      if (this.isRequestInDays) {
        dayValue.absenceDayOption = getAbsenceDayOption(dayValue);
      }

      const existingDay = existingDays.find((day) => day.date === periodDay);

      if (existingDay) {
        const value = state === 'hours' ? omit(existingDay, ['hours', 'absenceDayOption']) : existingDay;
        dayValue = {
          ...dayValue,
          ...value,
        };
      }

      if (this.isRequestInDays) {
        dayControl = new UntypedFormGroup({
          date: new UntypedFormControl(dayValue.date),
          isWeekend: new UntypedFormControl(dayValue.isWeekend),
          absenceDayOption: new UntypedFormControl(dayValue.absenceDayOption),
          day_time: new UntypedFormControl(dayValue.day_time, [ValidationService.timeValidator]),
        });
      } else {
        dayControl = new UntypedFormGroup({
          date: new UntypedFormControl(dayValue.date),
          hours: new UntypedFormControl(dayValue.hours, [
            ValidationService.maxHoursNotExceedDay,
            ValidationService.negativeNumberValidator,
          ]),
          partial_day: new UntypedFormControl(dayValue.partial_day),
          start_time: new UntypedFormControl({ value: dayValue.start_time, disabled: !dayValue.partial_day }, [
            ValidationService.timeValidator,
          ]),
          isWeekend: new UntypedFormControl(dayValue.isWeekend),
          wait_hours: new UntypedFormControl(dayValue.wait_hours),
        });
      }

      dayValue['time_off_balance_id'] = this.getTimeOffBalanceForDay(dayValue);

      dayControl.addControl('time_off_balance_id', new UntypedFormControl(dayValue.time_off_balance_id, []));

      this.subscribeToDayControl(dayControl);
      daysControl.push(dayControl);
    });

    if (this.form.contains('days')) {
      this.form.removeControl('days');
    }
    this.form.addControl('days', daysControl);
    this.dayControls = daysControl.controls;
    this.subscribeToAbsenceDays();
  }

  private subscribeToDayControl(control: UntypedFormGroup) {
    this.daySubscription.add(
      control.get('time_off_balance_id').valueChanges.subscribe((time_off_balance_id) => {
        this.dayControls?.forEach((dayToUpdate) => {
          if (dayToUpdate.value.date <= control.get('date').value) {
            return;
          }
          dayToUpdate.get('time_off_balance_id').setValue(time_off_balance_id, { emitEvent: false });
        });
      }),
    );

    this.daySubscription.add(
      combineLatest([this.expectedHoursReceived, fieldChangeWithStart(control)])
        .pipe(map(([, day]) => day))
        .subscribe((day) => {
          const { date, hours, start_time: startTime, absenceDayOption } = day;
          let partialDay = day.partial_day;
          let fromTime = null;
          let untilTime = null;

          this.startTimeControl(control, partialDay, hours);

          if (this.isRequestInDays) {
            partialDay =
              absenceDayOption === AbsenceDayOption.HALF_FROM || absenceDayOption === AbsenceDayOption.HALF_UNTIL;
            fromTime = absenceDayOption === AbsenceDayOption.HALF_FROM ? day.day_time : null;
            untilTime = absenceDayOption === AbsenceDayOption.HALF_UNTIL ? day.day_time : null;
          }

          let calculationType = AbsenceCalculationType.NONE;
          let hasConflict = false;
          let expectedHours = '00:00';
          const expectedDay = this.expectedHours && this.expectedHours.days[date];
          if (expectedDay) {
            calculationType = expectedDay.calculation_type;
            expectedHours = this.decimalToTimePipe.transform(expectedDay.hours);
            if (this.isRequestInDays) {
              // has conflict if the user has selected none and they are either scheduled or contacted with hours
              hasConflict = absenceDayOption === AbsenceDayOption.NONE && expectedDay.hours > 0;
            } else {
              hasConflict = expectedHours !== this.decimalToTimePipe.transform(hours);
            }
          }

          const absenteeOptionIdCtrl = this.form.get('absentee_option_id');

          const absenceConflictOptions: AbsenceConflictOptions = { partialDay, startTime, fromTime, untilTime, hours };
          this.absenteeDaysData[date] = {
            hasScheduleConflict: calculationType === AbsenceCalculationType.SCHEDULED && hasConflict,
            hasContractConflict: calculationType === AbsenceCalculationType.CONTRACT && hasConflict,
            hasAbsenceConflict: this.hasAbsenceConflict(date, absenceConflictOptions),
            hasConflictWithSameOptionId:
              absenteeOptionIdCtrl.valid &&
              this.hasAbsenceConflict(date, absenceConflictOptions, absenteeOptionIdCtrl.value),
            expectedHours,
          };

          this.checkPeriodConflict.next(null);
        }),
    );
  }

  private subscribeToPeriod() {
    void this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        pairwise(),
        map(([prevValues, currValues]) => ({
          periodChanged: prevValues?.period !== currValues?.period,
          absenteeOptionChanged: prevValues?.absentee_option_id !== currValues?.absentee_option_id,
        })),
        filter((changes) => !!Object.values(changes).filter(Boolean).length),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.includeTimeOffBalanceIds = [];
        this.fetchTimeOffBalances$.next(this.form.get('absentee_option_id').value);

        this.dayCalculationSubject.next({ state: 'absenteeDays' });
      });
  }

  private subscribeToPeriodConflict() {
    void this.checkPeriodConflict.pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const periodConflict = Object.values(this.absenteeDaysData).some(
        (absenteeData: AbsenteeDaysData) => absenteeData.hasConflictWithSameOptionId,
      );
      const periodCtrl = this.form.get('period');
      if (periodConflict) {
        periodCtrl.markAsDirty();
        periodCtrl.setValidators([
          Validators.required,
          ValidationService.rangePickerPeriodValidator(true),
          ValidationService.dateValidator,
          ValidationService.absenceConflict(periodConflict),
        ]);
        periodCtrl.updateValueAndValidity();
      } else {
        periodCtrl.setValidators([
          Validators.required,
          ValidationService.rangePickerPeriodValidator(true),
          ValidationService.dateValidator,
        ]);
        periodCtrl.updateValueAndValidity();
      }
    });
  }

  private subscribeToAbsenceConflict() {
    void fieldChangeWithStart(this.form.get('period'))
      .pipe(
        filter(() => isPeriodValid(this.form.get('period'))),
        switchMap((period) => {
          period = getPeriod(period);

          return this.store.pipe(
            select(getUserAbsenceForPeriod(this.absence.Employee.id, period[0], period[1], this.absence.id)),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((conflictAbsences) => {
        this.absenceConflictsForPeriod = conflictAbsences;
        this.expectedHoursReceived.next(false);
      });
  }

  public setHours() {
    this.dayCalculationSubject.next({ state: 'hours' });
  }

  public subscribeToAbsenceDays() {
    if (this.form.contains('days')) {
      void fieldChangeWithStart(this.form.get('days'))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((daysArray) => {
          this.totalHours = this.isRequestInDays ? 0 : totalHours(daysArray);
          this.totalDays = this.isRequestInDays ? totalDays(daysArray) : 0;

          if (this.hasWaitHours) {
            this.setTotalWaitHours();
          }
        });
    }
  }

  private updateDayValidators() {
    (this.form.get('days') as UntypedFormArray).controls.forEach((day: FormGroup) => {
      if (day.contains('start_time')) {
        if (day.get('partial_day')) {
          day.get('start_time')?.setValidators([Validators.required, ValidationService.timeValidator]);
        } else {
          day.get('start_time')?.setValidators([ValidationService.timeValidator]);
        }

        day.get('start_time')?.updateValueAndValidity();
      }

      if (day.contains('absenceDayOption')) {
        switch (day.get('absenceDayOption')?.value as AbsenceDayOption) {
          case AbsenceDayOption.FULL:
          case AbsenceDayOption.NONE: {
            day.get('day_time')?.clearValidators();

            break;
          }
          case AbsenceDayOption.HALF_UNTIL:
          case AbsenceDayOption.HALF_FROM: {
            day.get('day_time')?.setValidators([Validators.required, ValidationService.timeValidator]);
            break;
          }
        }
        day.get('day_time')?.updateValueAndValidity();
      }
    });
  }

  private hasAbsenceConflict(date: string, options: AbsenceConflictOptions, absenceTypeId?: string) {
    if (!this.absenceConflictsForPeriod || !this.absenceConflictsForPeriod[date]) {
      return false;
    }

    return this.absenceConflictsForPeriod[date].some(checkAbsenceConflict(date, options, absenceTypeId));
  }

  private isValidForAbsenceCheck() {
    return (
      this.hasAtleastPremium && isPeriodValid(this.form.get('period')) && this.form.get('absentee_option_id').valid
    );
  }

  private startTimeControl(control, partialDay: boolean, hours: string) {
    const startTimeCtrl: UntypedFormControl = control.get('start_time');
    if (!startTimeCtrl) {
      return;
    }
    const startTime = startTimeCtrl.value;
    this.disableStartTimeBasedOnPartialDay(startTimeCtrl, partialDay);

    if (isEmpty(startTime) || !partialDay) {
      startTimeCtrl.clearValidators();
      startTimeCtrl.updateValueAndValidity({ emitEvent: false });
      return;
    }

    this.checkForTimeConflict(startTimeCtrl, startTime, hours);
  }

  private disableStartTimeBasedOnPartialDay(startTimeCtrl: UntypedFormControl, partialDay: boolean) {
    if (partialDay && startTimeCtrl.disabled) {
      startTimeCtrl.enable({ emitEvent: false });
      return;
    }

    if (!partialDay && startTimeCtrl.enabled) {
      startTimeCtrl.disable({ emitEvent: false });
      startTimeCtrl.reset('00:00:00', { emitEvent: false });
    }
  }

  private checkForTimeConflict(startTimeCtrl: UntypedFormControl, startTime, hours: string) {
    const decimalStartTime = timeToDecimal(startTime);
    const hasTimeConflict = startTimeCtrl.hasError('absenceConflict');
    const timeValidity = decimalStartTime + parseFloat(hours) <= 24;
    if (hasTimeConflict && timeValidity) {
      startTimeCtrl.setValidators([ValidationService.timeValidator]);
      startTimeCtrl.updateValueAndValidity({ emitEvent: false });
      return;
    }

    if (!hasTimeConflict && !timeValidity) {
      startTimeCtrl.setValidators([ValidationService.timeValidator, ValidationService.absenceConflict(true)]);
      startTimeCtrl.updateValueAndValidity({ emitEvent: false });
    }
  }

  private getTimeOffBalanceForDay(dayValue: DayValue) {
    if (!this.canUpdateTimeOffBalances || !this.timeOffBalanceOptions || this.timeOffBalanceOptions?.length === 0) {
      return null;
    }

    if (!dayValue.time_off_balance_id) {
      return this.timeOffBalanceOptions[0].value;
    }

    const optionExist = this.timeOffBalanceOptions.some((balance) => dayValue.time_off_balance_id === balance.value);

    if (!optionExist) {
      return this.timeOffBalanceOptions[0].value;
    }

    return dayValue.time_off_balance_id;
  }

  private subscribeToPolicy() {
    void this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        map((values) => {
          const period = getPeriod(values.period);
          return { date: period[0] };
        }),
        distinctUntilChanged((prev, curr) => prev.date === curr.date),
        switchMap((payload) =>
          this.employeeService.loadAbsencePolicies(
            [this.absence.Employee.id],
            payload.date || format(new Date(), 'yyyy-MM-dd'),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((response) => {
        this.absencePolicyId$.next(response[0]?.absencePolicyId);
      });
  }

  private setTotalWaitHours() {
    this.totalWaitHours = totalWaitHours(this.form.get('wait_days').value, this.form.get('days')?.value);
  }

  public get isRequestInDays(): boolean {
    return this.selectedAbsenceType?.unit === AbsenceOptionUnit.DAYS;
  }

  public onSave() {
    const periodCtrl = this.form.get('period');
    if (!periodCtrl.valid && !periodCtrl.dirty) {
      periodCtrl.markAsDirty({ onlySelf: true });
      periodCtrl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }

    if (
      !this.form.valid ||
      this.loadingDays ||
      this.loading ||
      (this.hasAbsenceRestrictionConflict && !this.canBypassPolicy)
    ) {
      return;
    }

    if (this.form.contains('days')) {
      this.updateDayValidators();

      if (!this.form.valid) {
        return;
      }
    }

    const form = this.form.value;
    const period = getPeriod(form.period);
    const endDate = period[1];

    if (this.featureService.isFeatureActivated(Features.TMP_OPEN_ENDED_ABSENCES)) {
      form.open_ended = this.form.get('open_ended').value;
    }

    const data = {
      ...omit(this.form.value, ['days', 'hours', 'period']),
      startdate: period[0],
      // TODO stuff needed to please api, but not changeable through this form
      id: this.absence.id,
      user_id: this.absence.Employee.id,
    };

    if (!data['open_ended']) {
      data['enddate'] = endDate;
    }

    const absenteeDays = this.transformAbsenteeDaysToApiPayload(form.days);
    if (absenteeDays.length > 0) {
      data['AbsenteeDay'] = absenteeDays;
    }

    this.loading = true;

    void this.absenceService
      .save(data)
      .pipe(
        switchMap(() =>
          this.scheduleHelperService.updateSchedule({
            userId: this.absence.Employee.id,
            minDate: data.startdate,
            maxDate: endDate,
          }),
        ),
        finalize(() => (this.loading = false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          this.onEdited();
        },
        error: (err) => {
          this.trackEvent('Absence Request Modal Failed');
          handleSubmitError(err, this.form);
        },
      });
  }

  public onEdited() {
    this.dialogRef.close({
      action: 'edited',
      absenceStatus: this.absence.status,
    });
  }

  public onBack() {
    this.dialogRef.close({
      action: 'back',
    });
  }

  private transformAbsenteeDaysToApiPayload(days): AbsenteeDay[] {
    let waitHourDaysAmount = this.form.get('wait_days').value;

    return days.map((day) => {
      if (day.absenceDayOption) {
        const refactoredDay: Partial<AbsenteeDay> = {
          date: day.date,
          time_off_balance_id: day.time_off_balance_id,
        };
        if (day.absenceDayOption === AbsenceDayOption.FULL) {
          return { ...refactoredDay, days: 1 };
        }
        if (day.absenceDayOption === AbsenceDayOption.HALF_UNTIL) {
          return { ...refactoredDay, days: 0.5, until_time: day.day_time || DEFAULT_DAY_TIME };
        }
        if (day.absenceDayOption === AbsenceDayOption.HALF_FROM) {
          return { ...refactoredDay, days: 0.5, from_time: day.day_time || DEFAULT_DAY_TIME };
        }
        return { ...refactoredDay, days: 0 };
      }

      const refactoredDay: Partial<AbsenteeDay> = {
        ...omit(day, 'isWeekend'),
        start_time: day.start_time ? day.start_time : '00:00:00',
      };

      if (this.hasWaitHours && waitHourDaysAmount > 0 && day.hours > 0) {
        refactoredDay['wait_hours'] = day.hours.toString();
        waitHourDaysAmount -= 1;
      } else {
        refactoredDay['wait_hours'] = '0.00000';
      }

      return refactoredDay;
    });
  }
}

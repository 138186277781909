import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SbPlanLockDialogComponent } from '@app/shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.component';
import { LockType, planLockDialogConfig } from '@app/shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.model';
import { SbPlanDialogService } from '@app/shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { sbIcons } from '@sb/svg-icons';
import { SplitButtonMenuItem } from '@sb/ui';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, Subscription } from 'rxjs';

import { AppState } from '../../../../../reducers';
import { getAccountSubscription } from '../../../../../reducers/account/account.service';
import { DepartmentModel } from '../../../../../reducers/orm/department/department.model';
import { ScheduleFilterPeriod } from '../../../../../reducers/page-filters/page-filters.model';
import { lazySelect } from '../../../../../shared/lazy-select.observable';
import { hasAtleastSubscriptionPlan } from '../../../../../shared/subscription-plan/subscription-plan.directive';
import { PlanType, SubscriptionModel } from '../../../../+reports/shared/subscriptions/subscription.model';
import { Modal } from '../../../shared/interfaces';
import { PermissionState } from './../../../../../reducers/auth/auth.model';
import { getPermissionState, hasPermission } from './../../../../../reducers/auth/permission.helper';
import { format } from './../../../../../shared/date.helper';
import { PeriodType } from './../../../../../shared/interfaces';
import { Action } from './../../../shared/interfaces';
import { getTimesheetPageOptionsForSelectedDepartments } from './../../../shared/timesheet-helper.service';

@Component({
  selector: 'timesheet-team-dropdown',
  templateUrl: './timesheet-team-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetTeamDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public department: DepartmentModel;

  @Input()
  public isClosed: boolean;

  public primaryAction?: Action;

  private periodType: PeriodType;
  private formattedDate: string;
  private subscription = new Subscription();
  public schedulePeriod: ScheduleFilterPeriod;

  public accountSubscription: SubscriptionModel;

  private canCreateTimesheets: boolean;
  private canOpenOrCloseDays: boolean;
  private canClockOthers: boolean;

  public isLocked: boolean;
  public menuItems: SplitButtonMenuItem[];

  public constructor(
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    public sbPlanDialogService: SbPlanDialogService,
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      combineLatest(
        this.route.data,
        this.store.pipe(lazySelect(getPermissionState)),
        this.store.pipe(lazySelect(getTimesheetPageOptionsForSelectedDepartments)),
        this.store.pipe(lazySelect(getAccountSubscription)),
        this.sbPlanDialogService.isLocked,
      ).subscribe(([data, permissionState, options, accountSubscription, isLocked]) => {
        this.periodType = data.periodType;

        this.formattedDate = format(data.period, 'yyyy-MM-dd');
        this.isLocked = isLocked;
        this.menuItems = [];
        this.accountSubscription = accountSubscription;

        this.canCreateTimesheets = this.hasCreateTimesheets(permissionState);
        this.canOpenOrCloseDays = options.canOpenOrClose;
        this.canClockOthers = hasPermission(
          {
            permissions: 'Clock time',
            departments: this.department.id,
            userId: 'me',
          },
          permissionState,
        );
        this.createActionButtons();
      }),
    );
  }

  public ngOnChanges(): void {
    this.createActionButtons();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private createActionButtons() {
    const styleClass: string = this.isLocked ? 'locked' : '';
    const icon: sbIcons = this.isLocked ? 'lock' : null;

    this.menuItems = [];
    if (this.canCreateTimesheets && !this.isClosed) {
      this.menuItems.push({
        label: this.translate.instant('Add worked hours'),
        command: () => this.openModal('timesheet'),
      });
    }

    if (hasAtleastSubscriptionPlan(PlanType.EARLY_ADOPTER, this.accountSubscription) || this.isLocked) {
      if (this.canOpenOrCloseDays || this.isLocked) {
        this.menuItems.push({
          label:
            this.periodType === 'day'
              ? this.translate.instant('Open/Close day')
              : this.translate.instant('Open/Close days'),
          command: () =>
            this.isLocked ? this.openLockModal(LockType.OPEN_CLOSE_DAYS) : this.openModal('open-close-days'),
          styleClass,
          icon,
        });
      }
    }

    if (this.canClockOthers || this.isLocked) {
      this.menuItems.push({
        label: this.translate.instant('Clock employee'),
        command: () =>
          this.isLocked ? this.openLockModal(LockType.CLOCK_BOUNDARIES) : this.openModal('clock-employee'),
        styleClass,
        icon,
      });
    }
  }

  public openModal(modal: Modal) {
    switch (modal) {
      case 'open-close-days':
        this.router.navigate(['', { outlets: { modal: [modal, this.periodType, this.formattedDate] } }]);
        break;
      case 'clock-employee':
        this.router.navigate(['', { outlets: { modal: [modal, { department: this.department.id }] } }]);
        break;
      default:
        this.router.navigate([
          '',
          { outlets: { modal: [modal, { department: this.department.id, date: this.formattedDate }] } },
        ]);
        break;
    }

    this.cd.detectChanges();
  }

  public openLockModal(lockType: LockType) {
    this.dialogService.open(SbPlanLockDialogComponent, {
      data: {
        lockType: lockType,
      },
      ...planLockDialogConfig,
    });
  }

  public hasCreateTimesheets(permissionState: PermissionState) {
    return hasPermission(
      {
        permissions: ['Create timesheets', 'Create own timesheet'],
        userId: 'me',
      },
      permissionState,
    );
  }
}

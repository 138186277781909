import { NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@sb/ui';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

import { IconComponent } from '../../../../+authenticated/shared/icon.component';
import { KnowledgeBaseArticleLinkModule } from '../../../../pipes/knowledge-base-article-link.module';
import { AppState } from '../../../../reducers';
import { getPermissionState, hasPermission } from '../../../../reducers/auth/permission.helper';
import { FeatureService } from '../../../../startup/feature.service';
import { planLockMessages } from './plan-lock-messages';
import { LockType } from './sb-plan-lock-dialog.model';

@Component({
  selector: 'sb-plan-lock-dialog',
  templateUrl: 'sb-plan-lock-dialog.component.html',
  styleUrls: ['sb-plan-lock-dialog.component.scss'],
  standalone: true,
  imports: [IconComponent, TranslateModule, KnowledgeBaseArticleLinkModule, NgClass, ButtonComponent],
  providers: [DialogService],
})
export class SbPlanLockDialogComponent implements OnInit, OnDestroy {
  public canManageAccount: boolean;
  public lockType: LockType;

  public message = planLockMessages;

  private dataSubs = new Subscription();

  public constructor(
    private config: DynamicDialogConfig,
    private store: Store<AppState>,
    private router: Router,
    private featureService: FeatureService,
    public ref: DynamicDialogRef,
    private dialogService: DialogService,
  ) {}

  public ngOnInit() {
    this.lockType = this.config.data.lockType;

    this.dataSubs.add(
      this.store.select(getPermissionState).subscribe({
        next: (permissionState) => {
          this.canManageAccount = hasPermission(
            {
              permissions: 'Manage account',
              userId: 'me',
              departments: 'any',
            },
            permissionState,
          );
        },
      }),
    );
  }

  public goToUpgradePage() {
    this.ref.close();

    void this.router.navigate(['account/billing']);
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public goToUrl(url: string) {
    window.open(url, '_blank');
  }
}

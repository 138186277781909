<sb-dialog-header>
  <h3 sb-dialog-title>{{ 'Approving request' | translate }}</h3>
</sb-dialog-header>

<sb-dialog-content>
  <!-- TODO the spacing in this modal is extremely random, hopefully this will no longer need to be set once we settle on some generic modal/form styling -->
  <div class="mx-2.5 flex flex-col">
    <span class="text-sm font-semibold leading-5 text-word-mark-800">{{ 'Existing shifts' | translate }}</span>
    <span class="text-sm leading-5 text-grey-500">{{ 'What should happen to existing shifts?' | translate }}</span>
    <form class="mt-2 flex flex-col" [formGroup]="form" novalidate>
      <div class="flex items-center">
        <input
          type="radio"
          [id]="RosterActions.NONE"
          [formControl]="form.get('roster_action')"
          [value]="RosterActions.NONE"
        />
        <label class="ml-1" [for]="RosterActions.NONE">{{ 'Leave in schedule' | translate }}</label>
      </div>
      <div class="flex items-center">
        <input
          type="radio"
          [id]="RosterActions.HIDE"
          [formControl]="form.get('roster_action')"
          [value]="RosterActions.HIDE"
        />
        <label class="ml-1" [for]="RosterActions.HIDE">{{ 'Remove from schedule' | translate }}</label>
      </div>
      <ng-container *subscriptionPlan="PlanType.BASIC">
        <div class="flex items-center">
          <input
            type="radio"
            [id]="RosterActions.MOVE_TO_OPEN_SHIFT"
            [formControl]="form.get('roster_action')"
            [value]="RosterActions.MOVE_TO_OPEN_SHIFT"
          />
          <label class="ml-1" [for]="RosterActions.MOVE_TO_OPEN_SHIFT">{{ 'Move to open shift' | translate }}</label>
        </div>
      </ng-container>

      <sb-checkbox formControlName="hide_days_without_hours" class="mt-6">
        <div class="flex items-center">
          {{ 'Hide absentee days without value' | translate }}
          <sb-icon
            class="ml-2"
            [name]="'exclamation-circle'"
            [sbTooltip]="'Absentee days without value will not be shown in the schedule and timesheets.' | translate"
          />
        </div>
      </sb-checkbox>
    </form>
  </div>
</sb-dialog-content>

<sb-dialog-footer>
  <sb-dialog-footer-actions [align]="'end'">
    <button sb-button (click)="onBack()">
      {{ 'Back' | translate }}
    </button>
    <button sb-button [sbLadda]="saving" [color]="'primary'" (click)="onSave()">
      {{ 'Save' | translate }}
    </button>
  </sb-dialog-footer-actions>
</sb-dialog-footer>

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, distinctUntilChanged } from 'rxjs';
import { PlanType } from '../../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { getAccountSubscription } from '../../../../reducers/account/account.service';
import { getPermissionState, hasPermission } from '../../../../reducers/auth/permission.helper';
import { hasAtMostSubscriptionPlan } from '../../../../shared/subscription-plan/subscription-plan.directive';
import { AppState } from '../../../../reducers';

@Injectable({
  providedIn: 'root',
})
export class SbPlanDialogService {
  public constructor(private store: Store<AppState>) {}

  public isLocked = combineLatest([
    this.store.select(getAccountSubscription),
    this.store.select(getPermissionState),
  ]).pipe(
    map(
      ([accountSubscription, permissionState]) =>
        hasAtMostSubscriptionPlan(PlanType.FREE, accountSubscription) &&
        hasPermission(
          {
            permissions: ['Create roster', 'Edit roster', 'Delete roster'],
            userId: 'me',
            departments: 'any',
          },
          permissionState
        )
    ),
    distinctUntilChanged()
  );
}
